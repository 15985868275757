import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-map-searcher',
    templateUrl: './map-searcher.component.html',
    styles: [
        `
            .map {
                height: 500px;
                width: 100%;
            }

            .search-container {
                margin-bottom: 10px;
            }

            .search-box {
                width: 300px;
                padding: 5px;
            }
        `,
    ],
})
export class MapSearcherComponent implements AfterViewInit, OnChanges {
    @ViewChild('map', { static: true }) mapElement!: ElementRef;
    @ViewChild('searchBox', { static: true }) searchBoxElement!: ElementRef;
    @Output() locationSelected = new EventEmitter<{
        lat: number;
        lng: number;
    }>();
    @Input() lat: number | null = null;
    @Input() lng: number | null = null;
    @Input() disabled = false;

    map!: google.maps.Map;
    markers: google.maps.Marker[] = [];
    geocoder = new google.maps.Geocoder();

    constructor() {}

    ngAfterViewInit(): void {
        const mapOptions: google.maps.MapOptions = {
            center: { lat: this.lat || 14.634726, lng: this.lng || -86.966141 },
            zoom: this.lat && this.lng ? 17 : 7,
            disableDefaultUI: this.disabled,
            draggable: !this.disabled,
        };
        this.map = new google.maps.Map(
            this.mapElement.nativeElement,
            mapOptions
        );

        if (this.lat !== null && this.lng !== null) {
            const position = new google.maps.LatLng(this.lat, this.lng);
            this.addMarker(position);
            this.map.setCenter(position);
        }

        if (!this.disabled) {
            this.setupSearchBox();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled'] && this.map) {
            // Actualizar las opciones del mapa dinámicamente
            this.map.setOptions({
                disableDefaultUI: this.disabled,
                draggable: !this.disabled,
            });

            if (this.disabled) {
                this.clearMarkers(); // Limpiar marcadores si está deshabilitado
            }
        }
    }

    setupSearchBox(): void {
        const searchBox = new google.maps.places.SearchBox(
            this.searchBoxElement.nativeElement
        );

        this.map.addListener('bounds_changed', () => {
            if (!this.disabled) {
                searchBox.setBounds(
                    this.map.getBounds() as google.maps.LatLngBounds
                );
            }
        });

        searchBox.addListener('places_changed', () => {
            if (this.disabled) return; // Evitar acción si está deshabilitado

            const places = searchBox.getPlaces();

            if (!places || places.length === 0) {
                return;
            }

            this.clearMarkers();
            const bounds = new google.maps.LatLngBounds();

            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    return;
                }
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                this.locationSelected.emit({ lat, lng });

                this.addMarker(place.geometry.location);

                if (place.geometry.viewport) {
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });

            this.map.fitBounds(bounds);
        });

        this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
            if (this.disabled || !event.latLng) return; // Evitar acción si está deshabilitado

            this.clearMarkers();
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            this.locationSelected.emit({ lat, lng });
            this.addMarker(event.latLng);
        });
    }

    addMarker(position: google.maps.LatLng) {
        const marker = new google.maps.Marker({
            map: this.map,
            position: position,
        });
        this.markers.push(marker);
    }

    clearMarkers() {
        this.markers.forEach((marker) => marker.setMap(null));
        this.markers = [];
    }

    setSearchBoxValue(value: string, zoomLevel: number = 15) {
        if (this.disabled) return; // Evitar acción si está deshabilitado

        const inputElement = this.searchBoxElement
            .nativeElement as HTMLInputElement;
        inputElement.value = value;

        this.geocoder.geocode({ address: value }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results[0]) {
                const location = results[0].geometry.location;

                this.clearMarkers();
                this.addMarker(location);
                this.map.setCenter(location);
                this.map.setZoom(zoomLevel);

                this.locationSelected.emit({
                    lat: location.lat(),
                    lng: location.lng(),
                });
            } else {
                console.error('Error al geocodificar:', status);
            }
        });
    }
}
