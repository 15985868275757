import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from "@angular/core";

@Component({
  selector: "app-lazy-loaded-parent",
  templateUrl: "./lazy-loaded-parent.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyLoadedParentComponent implements AfterViewInit {
  @Input() rootMargin: string = "0px"; // Optional input to control when the content loads
  isVisible = false;

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.isVisible = true;
            observer.disconnect(); // Stop observing after the component is visible
          } else {
            this.isVisible = false;
          }

          this.cdr.detectChanges();
        });
      },
      {
        root: null,
        rootMargin: this.rootMargin,
        threshold: 0.1, // Load when 10% of the component is visible
      }
    );

    observer.observe(this.elementRef.nativeElement);
  }
}
