import { Component } from '@angular/core';

@Component({
    template: `
        <div
            class="flex h-full w-full flex-col items-center justify-center space-y-2"
        >
            <mat-spinner></mat-spinner>
            <span class="text-center text-2xl font-bold"
                >Procesando datos...</span
            >
            <small class="text-center">Espere un momento por favor</small>
        </div>
    `,
})
export class LoadingDialogComponent {}
