<div class="search-container w-full">
    <mat-form-field class="w-full">
        <mat-label>Buscar un lugar en el mapa</mat-label>
        <input
            matInput
            type="text"
            #searchBox
            placeholder="Buscar un lugar en el mapa..."
            class="search-box w-full"
            [disabled]="disabled"
        />
    </mat-form-field>
</div>
<div #map class="map"></div>
